<template>
  <section class="s-whatsapp-connection">
    <h2>Use o WhatsApp com a Reinvent</h2>

    <template v-if="getStatusCount >= getStatusCountMax">
      <h4>Para gerar um novo QR code, clique no botão abaixo</h4>

      <button
        @click="resetGetStatusCount"
        class="c-base-button c-base-button--white c-base-button--medium u-semibold"
      >
        Gerar QR Code
      </button>
    </template>

    <template v-else>
      <vue-qrcode
        v-if="qrCode"
        :value="qrCode"
        class="qr-code"
        type="image/jpeg"
        :color="{ dark: '#000000ff', light: '#ffffffff' }"
      />

      <p>
        Abra o WhatsApp, toque em Menu (Android) ou Configurações (iPhone),
        depois em Dispositivos conectados e escaneie o QR code.
      </p>

      <!-- <button
        @click="checkConnectionStatus"
        class="c-base-button c-base-button--white c-base-button--medium u-semibold"
      >
        Clique aqui após escanear
      </button> -->
    </template>
  </section>
</template>

<script>
import VueQrcode from "vue-qrcode";

const GET_STATUS_LOOP_MS = 20000;
const GET_STATUS_LOOP_MAX_TIMES = 3;

export default {
  name: "WhatsappConnection",
  components: { VueQrcode },
  emits: ["checkStatus"],
  props: {
    qrCode: { type: String, default: null },
  },
  data() {
    return {
      getStatusCount: 0,
      getStatusInterval: null,
      tenantID: this.$route.params.tenantID,
      getStatusCountMax:
        process.env.NODE_ENV === "production" ? GET_STATUS_LOOP_MAX_TIMES : 1,
    };
  },
  mounted() {
    this.getStatusLoop();
  },
  methods: {
    resetGetStatusCount() {
      this.getStatusCount = 0;
      clearInterval(this.getStatusInterval);
      this.getStatusInterval = null;

      this.getStatusLoop();
      this.checkConnectionStatus();
    },
    getStatusLoop() {
      this.getStatusInterval = setInterval(async () => {
        this.getStatusCount++;

        if (this.getStatusCount >= this.getStatusCountMax) {
          clearInterval(this.getStatusInterval);
          this.getStatusInterval = null;

          return;
        }

        this.checkConnectionStatus();
      }, GET_STATUS_LOOP_MS);
    },
    checkConnectionStatus() {
      this.$emit("checkStatus");
    },
  },
};
</script>

<style lang="scss" scoped>
.s-whatsapp-connection {
  width: 414px;
  // remove header height
  height: calc(100% - 92px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 0 auto;

  h2,
  h4,
  p {
    color: #fff;
  }

  h2,
  h4 {
    margin-bottom: 0;
  }

  p,
  h4 {
    text-align: center;
    font-size: 16px;
  }

  .qr-code {
    height: 280px;
    width: 280px;
    border-radius: 10px;
  }

  button {
    color: #000;
  }
}
</style>
