<template>
  <div style="padding: 0 20px" class="c-conversation-menu__body">
    <div class="c-conversation-menu__conversations">
      <RecycleScroller
        :items="contacts"
        :item-size="89"
        :buffer="windowHeight"
        key-field="contactID"
        page-mode=""
        v-slot="{ item }"
      >
        <SwipeOut :item="item" :threshold="100">
          <CardNewContact
            :contact="item"
            :selectedContact="selectedContact"
            :sessionLoading="sessionLoading"
            @click="initContactSession(item)"
          />

          <template v-slot:left>
            <div
              class="c-conversation-menu__swipeout-action c-conversation-menu__swipeout-action--resolve swipeout-action"
            >
              <div class="c-conversation-menu__swipeout-inner">
                <BaseIcon
                  class="c-conversation-menu__icon"
                  name="inbox_action_resolve"
                  size="26px"
                />
              </div>
            </div>
          </template>

          <template v-slot:right>
            <div
              class="c-conversation-menu__swipeout-action c-conversation-menu__swipeout-action--remove swipeout-action"
            >
              <div class="c-conversation-menu__swipeout-inner">
                <BaseIcon
                  class="c-conversation-menu__icon"
                  name="inbox_action_remove"
                  size="20px"
                ></BaseIcon>
              </div>
            </div>
          </template>
        </SwipeOut>
      </RecycleScroller>

      <VueInfiniteLoading @infinite="loadMoreContacts" ref="infiniteLoader">
        <template v-slot:spinner>
          <BaseSpinner v-if="contactsLoading" style="margin-top: 16px" />
        </template>
        <template v-slot:no-more>
          <div></div>
        </template>
        <template v-slot:no-results>
          <span style="color: #fff">Nenhum contato encontrado</span>
        </template>
      </VueInfiniteLoading>
    </div>
  </div>
</template>

<script>
import { SwipeOut } from "vue-swipe-actions";
import BaseSpinner from "@/components/Reinvent/Base/BaseSpinner.vue";
import CardNewContact from "@/components/Reinvent/Card/CardNewContact.vue";

export default {
  components: {
    SwipeOut,
    CardNewContact,
    BaseSpinner,
  },
  data() {
    return {
      contacts: [],
      contactsPage: 0,
      contactsLoading: false,
      contactsHasMore: false,
      sessionLoading: false,
      selectedContact: null,
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    getContacts(page) {
      this.contactsLoading = true;
      return this.$store
        .dispatch("contactsTalqui/acquireContacts", {
          tenantID: this.$route.params.tenantID,
          page: page,
          sortBy: "contactFirstname",
          sortDesc: 1,
          perPage: 10,
          filters: {
            "contactFirstname[$ne]": "",
          },
        })
        .then((response) => {
          this.contactsHasMore = response.__meta.hasMore;

          delete response.__meta;
          delete response.__status;

          this.contacts = this.contacts.concat(response);

          this.contactsLoading = false;

          if (this.$refs.infiniteLoader.stateChanger) {
            this.contactsHasMore
              ? this.$refs.infiniteLoader.stateChanger.loaded()
              : this.$refs.infiniteLoader.stateChanger.complete();
          }
        });
    },
    loadMoreContacts() {
      this.contactsPage += 1;
      this.getContacts(this.contactsPage);
    },
    initContactSession(contact) {
      if (!contact.contactLastSessionID) {
        this.sessionLoading = true;
        this.selectedContact = contact.contactID;
        this.$store
          .dispatch("sessionsTalqui/sessionStart", {
            tenantID: this.$route.params.tenantID,
            channel: "whatsapp-secondary",
            contactExternalID: contact.contactExternal["whatsapp-secondary"],
            contactFullName: contact.contactFirstname,
            messageKey: "text",
            messageValue: "Olá, tudo bem?",
          })
          .then((response) => {
            this.sessionLoading = false;
            this.selectedContact = null;

            this.handleRedirectToSession(response.session.sessionID);
          });
      } else {
        this.handleRedirectToSession(contact.contactLastSessionID);
      }
    },
    handleRedirectToSession(sessionID) {
      this.$router.push({
        name: "app.website.inbox.conversation.reinvent",
        params: {
          tenantID: this.$route.params.tenantID,
          sessionId: sessionID,
        },
      });

      this.$emit("onClose");
    },
  },
};
</script>
