import a from "@/enviroment.js";
import o from "@/classes/http.js";
import n from "@/classes/userAuth.js";
import r from "@/classes/language.js";
import s from "@/components/Base/BaseToast.vue";
import l from "@/classes/platformBridge.js";
import eventStorm from "@/classes/reinvent/eventStorm";

class _interceptors {
  constructor() {
    this.addErrorInterceptor();
    this.addLocaleInterceptor();
    this.autoAuthenticate();
  }
  addErrorInterceptor() {
    o.addInterceptor((e) => Promise.resolve(e), this.__errorHandler.bind(this));
  }
  addLocaleInterceptor() {
    o.addInterceptor(this.__localeHandler, (e) => Promise.reject(e));
  }
  autoAuthenticate() {
    n.autoAuthenticateApi();
  }
  __toastResponseError(e = {}) {
    s.error(e.message || e.reason || "runtime_unexpected_error");
  }
  __errorHandler(e = {}) {
    if (!1 === o.interceptorDisabled) {
      const t = e.status || -1;
      if ((401 === t || 451 === t) && !1 === o.unauthorizedDisabled) {
        eventStorm.eventsInbound["plugin:logout"]();
      }

      if (a.CRISP_OFFLINE_STATUS_CODES.includes(t)) {
        if (!1 !== o.offlineLockMode) {
          if (
            null === o.offlineScheduler &&
            (o.offlineCount++, o.offlineCount >= a.CRISP_OFFLINE_COUNT_MAX)
          ) {
            let t = e.headers || {},
              i = t["request-id"] || t["cf-ray"] || null,
              a = "/initiate/offline/";
            null !== i && (a += i + "/"),
              (o.interceptorDisabled = !0),
              l.redirectPage(a);
          }
        } else
          this.__toastResponseError(
            a.CRISP_OFFLINE_NO_REWRITE_REASON_CODES.includes(t) ? e : {},
          );
        return Promise.reject(e);
      }
      return (
        a.CRISP_EXPECTED_STATUS_CODES.includes(t) ||
          a.CRISP_EXPECTED_STATUS_CODES.includes((e || {}).reason) ||
          (e || {}).silent ||
          this.__toastResponseError(e),
        Promise.reject(e)
      );
    }
    return (o.offlineCount = 0), Promise.reject(e);
  }
  __localeHandler(e = {}) {
    if (e.__url && e.__url.includes("/user/account/profile"))
      try {
        if (
          !localStorage ||
          !window.SUPPORTS_LOCALE_OVERRIDE ||
          r.localeOverride
        )
          return Promise.resolve(e);
        e.locale && "" !== e.locale && e.locale !== window.LOCALE
          ? ((r.localeOverride = !0),
            (window.LOCALE = e.locale),
            localStorage.setItem("locale_override", e.locale))
          : (null !== e.locale || "" === e.locale) &&
            localStorage.getItem("locale_override") &&
            r.localeOverride &&
            ((r.localeOverride = !0),
            (window.LOCALE = localStorage.getItem("locale_override")),
            localStorage.removeItem("locale_override"));
        // eslint-disable-next-line no-empty
      } catch (t) {}
    return Promise.resolve(e);
  }
}
export default new _interceptors();
