import push from "push.js";
import audioHelper from "@/classes/talqui/audioHelper";
import { initializeApp } from "firebase/app";
import { getToken, isSupported } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";
import logger from "@/initializers/vueLogHandler.js";
import store from "@/store";

class _notification {
  constructor() {
    this.currentNotificationId = null;
    this.channel = new BroadcastChannel("frbm");
  }

  loadNotifications() {
    this.loadLocalNotifications();
    // this.loadFCM(); // disabled because reinvent won't use this
  }

  loadLocalNotifications() {
    push.Permission.request(
      () => {
        this.onGranted && this.onGranted();
      },
      () => {
        this.onDenied && this.onDenied();
      },
    );
  }

  playSound({ eventType, audioSource, inLoop = false, gain = 50 }) {
    audioHelper.play(eventType, audioSource, inLoop, gain);
  }

  getPermissions() {
    return push.Permission.get();
  }

  push({
    title = "Talqui",
    body = "Você tem uma nova notificação na Talqui",
    delay = 0,
    tenantID = "",
    sessionID = "",
    sound = false,
    isFromFCM = !1,
  }) {
    logger.log("@class:notifications:push", {
      title,
      body,
      delay,
      tenantID,
      sessionID,
      sound,
      isFromFCM,
    });
    /**
     * Just shows notification for current tenant open
     */
    const currentTenantID = store.getters["tenantsTalqui/getTenantID"];
    if (tenantID !== currentTenantID) {
      return;
    }

    /**
     * Check operator notification preferences
     *
     * If is all disabled, nothing will be notified
     */
    const operatorNotifications =
      store.getters["operatorsTalqui/getNotification"];
    if (operatorNotifications?.allDisabled || false === true) {
      return;
    }

    /**
     * Check if operator authorize sounds in notifications
     */
    if (operatorNotifications.allowSounds === true && sound) {
      audioHelper.play("events", "sounds/events/session-type-queued");
    }

    push.create(title, {
      body,
      icon: "https://talqui.chat/favicon-256x256.png",
      timeout: 5000,
      requireInteraction: true,
      onClick: function () {
        window.focus();
        this.close();
      },
    });
  }

  loadFCM() {
    try {
      /**
       * Avoid unsuported browsers
       */
      if (!isSupported()) {
        return Promise.resolve();
      }

      initializeApp({
        apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_SENDERID,
        appId: process.env.VUE_APP_FIREBASE_APPID,
        measurementId: process.env.VUE_APP_FIREBASE_MENSUREMENTID,
      });
      const messaging = getMessaging();

      // messaging.onMessageHandler = (payload) => {
      //   console.log("@onMessageHandler", payload);
      // };

      // messaging.onBackgroundMessageHandler = (payload) => {
      //   console.log("onBackgroundMessageHandler", payload);
      // };

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.onmessage = (payload) => {
          logger.log("notification:fbms:presence", payload);

          this.push({
            body: payload?.data?.data?.body || undefined,
            title: payload?.data?.data?.title || undefined,
            tenantID: payload?.data?.data?.tenantID || null,
            sessionId: payload?.data?.data?.sessionID || null,
            isFromFCM: payload?.data?.isFirebaseMessaging,
            sound: true,
          });
        };

        /**
         * Notifications received in background
         */
        if ("BroadcastChannel" in window) {
          this.channel.onmessage = (payload) => {
            logger.log("notification:fbms:remote", payload);
          };
        }

        navigator.serviceWorker
          .register("firebase-messaging-sw.js")
          .then((registration) => {
            getToken(messaging, {
              vapidKey: process.env.VUE_APP_FIREBASE_VAPIKEY,
              serviceWorkerRegistration: registration,
            })
              .then((token) => {
                token && this.syncNotifications(token);
              })
              .catch((err) => {
                logger.warn(err);
              });
          });
      }
    } catch (e) {
      logger.error(e);
    }
  }

  syncNotifications(token) {
    store.commit("operatorsTalqui/setNotificationToken", token);
    store.dispatch("operatorsTalqui/operatorAvailability", {
      status: "online",
      time: {
        for: 310,
      },
    });
  }
}

export default new _notification();
