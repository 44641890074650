<template>
  <div class="c-conversation-menu-footer">
    <!-- <div
      class="c-conversation-menu-routing c-conversation-menu-footer__routing"
    >
      <ConversationMenuRouting
        class="c-conversation-menu-footer__routing"
        v-on:routingClick="onRoutingClick"
      />
    </div> -->

    <!-- <div class="c-conversation-menu-footer__operators">
      <BaseNotification
        v-if="operatorsHasMore.length > 0"
        size="none"
        class="c-conversation-menu-footer__notification u-bold u-has-tooltip"
        color="grey"
      >
        <BaseTooltip :multiline="true">
          Também online:
          <template #details>
            <div v-html="operatorsHasMorePlain" />
          </template>
        </BaseTooltip>
        <span>+{{ operatorsHasMore.length }}</span>
      </BaseNotification>

      <BaseAvatar
        v-for="operator in filteredOperators"
        :key="operator.operatorID"
        class="c-conversation-menu-footer__operator u-has-tooltip"
        :avatar-id="operator.operatorID"
        :avatar-url="operator.operatorPhoto"
        :availability="operatorAvailability(operator)"
        :showAvailabilityAway="true"
        cursor="default"
        size="small"
        type="operator"
      >
        <BaseTooltip>
          {{ operator.operatorFirstname + " " }}
          {{
            operatorAvailability(operator) === "online"
              ? this.$t("TOOLTIPS.IS_ONLINE")
              : this.$t("TOOLTIPS.IS_AWAY")
          }}
        </BaseTooltip>
      </BaseAvatar>
    </div> -->

    <div class="c-conversation-menu-footer__actions">
      <BaseDropdown
        v-if="dropdown.items.length > 0"
        v-on:itemClick="onActionClick"
        :items="dropdown.items"
        :tooltip="{
          content: this.$t('INBOX.LIST.CONTROLS_MORE_TOOLTIP'),
          direction: 'top',
        }"
        direction="top"
      />
    </div>
  </div>
</template>
<script>
// import * as o from "@/classes/linkHelper.js";
const MAX_DISPLAY_OPERATORS_ONLINE = 6;

// import ConversationMenuRouting from "@/components/ConversationMenuRouting.vue";
import mixinPlugins from "@/mixins/plugins.js";
import BaseDropdown from "@/components/Reinvent/Base/BaseDropdown.vue";
import mixinOperatorAvailability from "@/mixins/operatorAvailability.js";

export default {
  components: {
    BaseDropdown,
    // ConversationMenuRouting,
  },
  mixins: [mixinPlugins, mixinOperatorAvailability],
  props: {
    operators: {
      type: Object,
      default: () => ({}),
    },
    tenantID: {
      type: String,
      required: true,
    },
  },
  emits: [
    "batchReadAll",
    "batchResolveAll",
    "createConversation",
    "routingClick",
  ],
  data() {
    return {
      dropdown: {
        items_filter: [
          {
            id: "create_conversation",
            icon: "people",
            label: this.$t("INBOX.LIST.CONTROLS_MORE_NEW_CONVERSATION"),
          },
        ],
        items: [
          // {
          //   id: "create_conversation",
          //   icon: "add",
          //   label: this.$t("INBOX.LIST.CONTROLS_MORE_NEW_CONVERSATION"),
          // },
          // {
          //   id: "batch_read",
          //   icon: "done_all",
          //   label: this.$t("INBOX.LIST.CONTROLS_MORE_REAL_ALL"),
          // },
          // {
          //   id: "batch_resolve",
          //   icon: "done_all",
          //   label: this.$t("INBOX.LIST.CONTROLS_MORE_RESOLVE_ALL"),
          // },
          // {
          //   id: "shortcuts",
          //   icon: "keyboard",
          //   label: this.$t("INBOX.LIST.CONTROLS_MORE_KEYBOARD_SHORTCUTS"),
          // },
        ],
      },
    };
  },
  mounted() {
    if (this.$pluginExists("urn:talqui:whatsapp-secondary:0")) {
      this.dropdown.items.push({
        id: "create_conversation",
        icon: "add",
        label: this.$t("INBOX.LIST.CONTROLS_MORE_NEW_CONVERSATION"),
      });
    }
  },
  computed: {
    filteredOperators() {
      const operators = (this.operators || []).slice(
        0,
        MAX_DISPLAY_OPERATORS_ONLINE,
      );

      return operators;
    },
    operatorsHasMore() {
      return (this.operators || []).slice(MAX_DISPLAY_OPERATORS_ONLINE);
    },
    operatorsHasMorePlain() {
      return this.operatorsHasMore
        .map((operator) => operator.operatorFirstname)
        .join("<br>");
    },
  },
  methods: {
    onActionClick(e) {
      switch (e) {
        case "create_conversation":
          this.$emit("createConversation");
          break;
        case "batch_read":
          this.$emit("batchReadAll");
          break;
        case "batch_resolve":
          this.$emit("batchResolveAll");
          break;
        case "shortcuts":
          // o.default.openExternal(
          //   this.$CONFIG.CRISP_LINKS.HELP.KEYBOARD_SHORTCUTS
          // );
          break;
      }
    },
    onRoutingClick(e) {
      this.$emit("routingClick", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.c-conversation-menu-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding: 0 20px;
  justify-content: flex-end;
  .c-conversation-menu-footer__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .c-conversation-menu-footer__operators {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    z-index: 2;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    .c-conversation-menu-footer__notification {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      letter-spacing: 0.5px;
      margin-left: 2px;
      padding: 2px 6px 0;
      border-radius: 2px;
    }
    .c-conversation-menu-footer__operator {
      margin-left: -6px;
      border: 2px solid #fff;
      border-radius: 100%;
      -webkit-animation-name: bounceIn;
      animation-name: bounceIn;
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      .c-base-avatar__status {
        display: none;
      }
      &:last-of-type {
        margin-left: 0;
        .c-base-avatar__status {
          display: block;
        }
      }
    }
  }
  .c-conversation-menu-footer__routing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: #3f536e;
    cursor: pointer;
    .c-base-icon {
      fill: #3f536e;
    }
  }
}
</style>
