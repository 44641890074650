class ConversationMapper {
  constructor() {
    this.conversation = null;
  }

  sessionStartResponseConverter(response) {
    this.conversation = {
      tenantID: response.contact.tenantID,
      contactID: response.contact.contactID,
      contactFirstname: response.contact.contactFirstname,
      contactLastname: response.contact.contactLastname,
      contactPhone: response.contact.contactPhone,
      contactPhoto: response.contact.contactPhoto,
      contactLastMessageID: response.contact.contactLastMessageID,
      contactLastMessageAt: response.contact.contactLastMessageAt,
      contactLastMessageSeenAt: response.contact.contactLastMessageSeenAt,
      contactLastSessionID: response.contact.contactLastSessionID,
      createdAt: response.contact.createdAt,
      updatedAt: response.contact.updatedAt,
      __lastMessage: {
        messageID: response.message.messageID,
        messageFingerprint: response.message.messageFingerprint,
        messageDirection: response.message.messageDirection,
        messageKey: response.message.messageKey,
        messageValue: response.message.messageValue,
        messageStatus: response.message.messageStatus,
        createdAt: response.message.createdAt,
      },
    };
  }

  /**
   * some socket events may not return this properties
   * and we can not update the persisted state with empty values
   */
  messageSocketEventConverter(tenantID, payload) {
    this.conversation = {
      tenantID: tenantID,
      contactID: payload?.contactID,
      ...(payload?.contact?.contactFirstname && {
        contactFirstname: payload?.contact?.contactFirstname,
      }),
      ...(payload?.contact?.contactLastname && {
        contactLastname: payload?.contact?.contactLastname,
      }),
      ...(payload?.contact?.contactPhone && {
        contactPhone: payload?.contact?.contactPhone,
      }),
      ...(payload?.contact?.contactPhoto && {
        contactPhoto: payload?.contact?.contactPhoto,
      }),
      contactLastMessageID: "",
      contactLastMessageAt: null,
      contactLastMessageSeenAt: null,
      contactLastSessionID: payload?.sessionID,
      createdAt: null,
      updatedAt: null,
      __lastMessage: payload?.lastMessage,
    };
  }
}

export default ConversationMapper;
