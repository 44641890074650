<template>
  <div
    style="
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 16px 0;
      border-bottom: 1px solid rgba(94, 94, 94, 0.15);
      cursor: pointer;
    "
  >
    <BaseAvatar
      :avatar-id="contact.contactID"
      :avatar-url="contact.contactPhoto"
      size="medium"
    />

    <div style="display: flex; flex-direction: column; gap: 4px">
      <span style="font-weight: 700; color: #fff">
        {{ contact.contactFirstname }}
      </span>

      <span style="font-weight: 700; color: #fff">
        {{ contact.contactPhone }}
      </span>
    </div>

    <BaseSpinner
      v-if="sessionLoading && contact.contactID === selectedContact"
    />
  </div>
</template>

<script>
import BaseSpinner from "@/components/Reinvent/Base/BaseSpinner.vue";

export default {
  components: { BaseSpinner },
  props: {
    contact: { type: Object, default: () => {} },
    selectedContact: { type: String, default: "" },
    sessionLoading: { type: Boolean, default: false },
  },
};
</script>
