const pubMessage = ({ name, data }) => {
  const event = { name, data };
  window.parent.postMessage(JSON.stringify(event), "*");
};

const eventsInbound = {
  "plugin:session": (data) => pubMessage({ name: "plugin:session", data }),

  "plugin:whatsappConnection": (data) =>
    pubMessage({ name: "plugin:whatsappConnection", data }),

  "plugin:logout": (data) => pubMessage({ name: "plugin:logout", data }),

  "plugin:evaluateInvestorsCommunicationBackwash": (data) =>
    pubMessage({ name: "plugin:evaluateInvestorsCommunicationBackwash", data }),
};

export default { eventsInbound };
