// import tenant from "@/classes/talqui/tenant";
import socket from "./socket";
import store from "@/store";
import notification from "@/classes/talqui/notification";
import BaseToast from "@/components/Base/BaseToast.vue";
import env from "@/enviroment";
import logger from "@/initializers/vueLogHandler.js";
import sha1 from "crypto-js/sha1";

const $socketEvents = [
  {
    name: "message:inbound",
    function: "messageInbound",
    canHandle: true,
    type: "socket",
  },
  {
    name: "message:outbound",
    function: "messageOutbound",
    canHandle: true,
    type: "socket",
  },
  {
    name: "message:deleted",
    function: "messageDeleted",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:request:initiated",
    function: "sessionRequestInitiated",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:set:opened",
    function: "sessionSetOpened",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:set:routing",
    function: "sessionSetRouting",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:set:change_type",
    function: "sessionSetChangeType",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:tag:added",
    function: "sessionTagAdded",
    canHandle: true,
    type: "socket",
  },
  {
    name: "session:set:closed",
    function: "sessionSetClosed",
    canHandle: true,
    type: "socket",
  },
  {
    name: "contact:attributes:update",
    function: "contactAttributesUpdate",
    canHandle: true,
    type: "socket",
  },
  {
    name: "organization:operators:availability-changed",
    function: "organizationOperatorsAvailabilityChanged",
    canHandle: true,
    type: "socket",
  },
];

class _operation {
  constructor() {
    this.events();
    this.whoami = store.getters["operatorsTalqui/getMe"];
  }

  bindSocket(eventName, eventFunction) {
    socket.on(eventName, eventFunction);
  }

  unbindSocket(eventName) {
    socket.off(eventName);
  }

  events() {
    $socketEvents.forEach((action) => {
      this.bindSocket(action.name, this[action.function].bind(this));
    });
  }

  getFingerprint(args) {
    return sha1(args.join("-")).toString();
  }

  __sendMessage({ message }) {
    return new Promise((resolve) => {
      socket.emit("message:dispatch", message, (callback) => {
        let $session = store.getters["sessionsTalqui/getSession"](
          message.tenantID,
          message.sessionID,
        );
        let $operator = store.getters["operatorsTalqui/getMe"];

        const $messageItem = {
          messageID: callback.messageID,
          tenantID: $session.tenantID,
          contactID: $session.contactID,
          sessionID: $session.sessionID,
          operatorID: message.operatorID || null,
          operator: $operator,
          messageAutonomous: false,
          messageExternalID: null,
          messageChannel: $session.sessionChannel,
          messageDirection: "outbound",
          messageKey: message.messageKey,
          messageValue: message.messageValue,
          messageMeta: {},
          messageStatus: -1,
          messageFingerprint: this.getFingerprint([callback.messageID]),
          createdAt: new Date(),
        };

        store.commit("sessionsTalqui/addMessage", {
          message: $messageItem,
        });

        resolve(callback);
      });
    });
  }

  async __deleteMessage({ tenantID, sessionID, messageFingerprint }) {
    return store
      .dispatch("sessionsTalqui/deleteMessage", {
        tenantID,
        sessionID,
        messageFingerprint,
      })
      .then((res) => {
        if (res?.status === 200) {
          store.commit("sessionsTalqui/removeMessage", {
            tenantID,
            sessionID,
            messageFingerprint,
          });
          return Promise.resolve(true);
        }
        return Promise.reject(null);
      })
      .catch((err) => {
        logger.error("@delete:message:error", err);
        return Promise.reject(null);
      });
  }

  messageDeleted(payload) {
    logger.log("sock@message:deleted", payload);
    store.commit("sessionsTalqui/removeMessage", {
      tenantID: payload.tenantID,
      sessionID: payload.sessionID,
      messageFingerprint: payload.messageFingerprint,
    });
  }

  messageInbound(payload) {
    logger.log("sock@message:inbound", payload);
    return Promise.resolve().then(() => {
      store.commit("sessionsTalqui/assertTenantSessions", {
        tenantID: payload.tenantID,
      });

      /**
       * Push new message content to stores
       */
      store.commit("sessionsTalqui/assertSession", {
        tenantID: payload.tenantID,
        sessionID: payload.sessionID,
      });
      store.commit("sessionsTalqui/addMessage", { message: payload });

      /**
       * Updating last message to re-order conversation menu
       */
      store.commit("conversationsReinvent/updateLastMessage", {
        tenantID: payload.tenantID,
        contactID: payload.contactID,
        message: payload,
      });

      if (payload.messageDirection === "inbound") {
        notification.playSound({
          eventType: "events",
          audioSource: env.TALQUI_SOUNDS_CHAT_MESSAGE_RECEIVE,
        });

        /**
         * Only add unread counter if conversation is not
         * currently opened on screen
         */
        const $currentTenantID = store.getters["tenantsTalqui/getTenantID"];
        const $currentSession =
          store.getters["sessionsTalqui/getCurrentSession"]($currentTenantID);
        if (payload?.contactID !== $currentSession?.contact?.contactID) {
          store.commit("conversationsReinvent/addUnreadCounter", {
            tenantID: payload.tenantID,
            contactID: payload.contactID,
          });
        }
      }
      return Promise.resolve();
    });
  }

  messageOutbound(payload) {
    logger.log("sock@message:outbound", payload);
    return Promise.resolve().then(() => {
      store.commit("sessionsTalqui/assertTenantSessions", {
        tenantID: payload.tenantID,
      });
      store.commit("sessionsTalqui/assertSession", {
        tenantID: payload.tenantID,
        sessionID: payload.sessionID,
      });
      store.commit("sessionsTalqui/addMessage", { message: payload });

      store.commit("conversationsReinvent/updateLastMessage", {
        tenantID: payload.tenantID,
        contactID: payload.contactID,
        message: payload,
      });
    });
  }

  sessionRequestInitiated(payload) {
    logger.log("sock@session:request:initiated", payload);

    /**
     * Acquire most recent conversation data
     * for new session started
     */
    return new Promise(() => {
      /**
       * Read single conversation from API
       */
      return store.dispatch("conversationsReinvent/acquireConversation", {
        tenantID: payload.tenantID,
        contactID: payload.session.contactID,
      });
    }).then(() => {
      store.commit("sessionsTalqui/storeSession", {
        tenantID: payload.tenantID,
        sessionID: payload.sessionID,
        session: payload.session,
      });
    });
  }

  sessionSetOpened(payload) {
    if (payload.operatorID !== this.whoami.operatorID) {
      logger.log("sock@session:set:opened", payload);
    }
  }

  sessionSetRouting(payload) {
    logger.log("sock@session:set:routing", payload);
    store.commit("sessionsTalqui/storeSession", {
      tenantID: payload.tenantID,
      sessionID: payload.sessionID,
      session: payload.session,
    });

    /**
     * Decrement counter in tab "Queued"
     */
    store.dispatch("notificationsTalqui/decrementNotification", {
      tenantID: payload.tenantID,
      notificationType: "queuedCount",
    });

    if (payload?.session?.operatorID === this.whoami.operatorID) {
      /**
       * Increment counter in tab "Mine" sessions
       */
      store.dispatch("notificationsTalqui/incrementNotification", {
        tenantID: payload.tenantID,
        notificationType: "manualPendingCount",
      });
    }
  }

  contactAttributesUpdate(payload) {
    logger.log("sock@contact:attributes:update", payload);
    const $currentTenantID = store.getters["tenantsTalqui/getTenantID"];
    const $currentSession =
      store.getters["sessionsTalqui/getCurrentSession"]($currentTenantID);
    if (payload?.contact?.contactID === $currentSession?.contact?.contactID) {
      let $session = store.getters["sessionsTalqui/getSession"](
        payload.tenantID,
        $currentSession.sessionID,
      );
      $session.contact = payload.contact;
      BaseToast.info("contact_attributes_updated", true);
    }
  }

  sessionSetClosed(payload) {
    logger.log("sock@session:set:closed", payload);
    store.commit("sessionsTalqui/storeSession", {
      tenantID: payload.tenantID,
      sessionID: payload.sessionID,
      session: payload.session,
    });

    /**
     * Decrement counter in tab "Mine"
     */
    if (payload?.responsibleOperatorID === this.whoami.operatorID) {
      store.dispatch("notificationsTalqui/decrementNotification", {
        tenantID: payload.tenantID,
        notificationType: "manualPendingCount",
      });
    }
  }

  sessionSetChangeType(payload) {
    if (payload.operatorID !== this.whoami.operatorID) {
      logger.log("sock@session:set:change_type", payload);
      store.commit("sessionsTalqui/storeSession", {
        tenantID: payload.tenantID,
        sessionID: payload.sessionID,
        session: payload.session,
      });

      /**
       * :business:rule:
       * Quando reproduzir som de notificação?!
       * Se, somente se, a sessão foi movida para a FILA
       * e eu tenho em minhas preferências de usuário
       * esta configuração de notiicação ativada
       */
      if (payload.session.sessionType === "queued") {
        notification.playSound({
          eventType: "events",
          audioSource: env.TALQUI_SOUNDS_SESSION_QUEUED,
        });

        /**
         * Increment counter in tab "Queued" sessions
         */
        store.dispatch("notificationsTalqui/incrementNotification", {
          tenantID: payload.tenantID,
          notificationType: "queuedCount",
        });
      }
      //sock@session:set:closed
    }
  }

  sessionTagAdded(payload) {
    logger.log("sock@session:tag:added", payload);
    store.commit("sessionsTalqui/storeSession", {
      tenantID: payload.tenantID,
      sessionID: payload.sessionID,
      session: payload.session,
    });
  }

  organizationOperatorsAvailabilityChanged(payload) {
    if (payload.operatorID !== this.whoami.operatorID) {
      logger.log("sock@organization:operators:availability-changed", payload);
      store.dispatch("operatorsTalqui/acquireOperators", {
        tenantID: payload.tenantID,
      });
    }
  }

  messageAcknowledgeDelivered({ tenantID, sessionID, message }) {
    store.commit("sessionsTalqui/assertSession", {
      tenantID: tenantID,
      sessionID: sessionID,
    });

    return store.commit("sessionsTalqui/setDeliveredMessage", {
      tenantID: tenantID,
      sessionID: sessionID,
      messageFingerprint: message.messageFingerprint,
    });
  }

  messageAcknowledgeReadReceived(payload) {
    return store
      .dispatch("conversations/assertCache", {
        event: payload,
      })
      .then(
        () => (
          store.commit("conversations/setReadMessage", {
            tenantID: payload.tenantID,
            sessionID: payload.sessionID,
            event: payload,
            direction: "received",
          }),
          Promise.resolve()
        ),
      )
      .catch(() => {});
  }
}

export default new _operation();
