<template>
  <div class="c-inbox-index o-layout o-layout--flex o-layout--columns">
    <BaseSpinner v-if="loadingStatus" style="margin: 100px auto" />

    <template v-else>
      <InboxWhatsAppConnect
        v-if="pluginStatus?.connected === false"
        :qrCode="pluginStatus.qrcode"
        @checkStatus="checkConnectionStatus"
      />

      <div v-else class="o-layout__rows" style="gap: 12px">
        <ConversationMenu class="c-inbox-index__menu"></ConversationMenu>

        <div
          v-if="'app.website.inbox.index.reinvent' === this.$route.name"
          class="c-inbox-index__content"
        >
          <BaseIcon name="reinvent-logotipo-01" height="32px" width="178px" />
        </div>

        <router-view v-else></router-view>
      </div>
    </template>
  </div>
</template>

<script>
import mitt from "mitt";
import BaseSpinner from "@/components/Reinvent/Base/BaseSpinner.vue";
import ConversationMenu from "@/components/Reinvent/Conversation/ConversationMenu.vue";
import InboxWhatsAppConnect from "@/components/Reinvent/Inbox/InboxWhatsAppConnect.vue";

const emitter = mitt();
const WHATSAPP_PLUGIN_URN = "urn:talqui:whatsapp-secondary:0";

export default {
  components: {
    BaseSpinner,
    ConversationMenu,
    InboxWhatsAppConnect,
  },
  data() {
    return {
      tenantID: "",
      loadingStatus: false,
    };
  },
  computed: {
    pluginConnectionID() {
      return this.$store.getters["pluginsTalqui/getPluginConnection"](
        WHATSAPP_PLUGIN_URN,
      )?.pluginConnectionID;
    },
    pluginStatus() {
      return this.$store.getters["pluginsTalqui/getPluginStatus"](
        this.pluginConnectionID,
      );
    },
  },
  created() {
    emitter.on("plugin:init", this.checkConnectionStatus);
  },
  beforeUnmount() {
    emitter.off("plugin:init", this.checkConnectionStatus);
  },
  checkConnectionStatus() {
    emitter.emit("plugin:init");
  },
  methods: {
    checkConnectionStatus() {
      if (!this.pluginConnectionID) return;
      this.loadingStatus = true;

      this.$store
        .dispatch("pluginsTalqui/pluginConnectionStatus", {
          tenantID: this.tenantID,
          pluginConnectionID: this.pluginConnectionID,
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
  },
  watch: {
    $route: {
      immediate: !0,
      handler(e) {
        this.tenantID = e.params.tenantID;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.c-inbox-index {
  position: relative;
  .c-inbox-index__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 40px 20px;
    border-radius: 20px;
    background: rgba(243, 244, 244, 0.2);
    .c-inbox-index__logo {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .c-inbox-index__reward-box {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-top: 24px;
    }
  }
}
@media (max-width: 75em) {
  .c-inbox-index {
    .c-inbox-index__menu {
      .c-conversation-menu-item {
        .c-conversation-menu-item__container {
          .c-conversation-menu-item__headlines {
            .c-conversation-menu-item__assignment {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
