import conversations from "@/classes/reinvent/conversations";
import thenby from "thenby";

const SESSION_PRONOUMS = [
  "Entusiasta",
  "Explorador",
  "Aventureiro",
  "Viajante",
  "Descobridor",
  "Desbravador",
  "Curioso",
  "Pioneiro",
  "Desbravador",
  "Inovador",
  "Destemido",
  "Corajoso",
  "Admirador",
];

export default {
  namespaced: true,
  state: {
    conversationsMap: {},
    conversationsList: {},
    currentConversationID: null,
    cacheTimeout: {},
  },
  getters: {
    getConversationReference: (state) => (tenantID, contactID) =>
      state.conversationsMap[tenantID][contactID],
    getConversationIndex: (state) => (tenantID, contactID) =>
      (state.conversationsList[tenantID] || []).findIndex(
        (conv) => conv.contactID === contactID,
      ),
    getConversations: (state) => (tenantID) =>
      state.conversationsList[tenantID] || [],
  },
  actions: {
    acquireConversations({ commit }, { tenantID, page, perPage = 50 }) {
      /**
       * Prepare parameters for
       * execute request
       */
      let conversationsBatch = [];
      let REQUEST_PARAMETERS = {
        page: page,
        perPage: perPage,
      };

      /**
       * Request conversation list batch from API
       */
      return Promise.resolve()
        .then(() =>
          conversations.conversations(tenantID).get(REQUEST_PARAMETERS),
        )
        .then((res) => {
          conversationsBatch = res?.conversations || [];
          commit("assertConversations", {
            tenantID: tenantID,
          });
          return res;
        })
        .then((res) => {
          if (conversationsBatch.length > 0) {
            conversationsBatch.forEach((conversationItem) => {
              commit("storeConversation", {
                tenantID: conversationItem.tenantID,
                contactID: conversationItem.contactID,
                conversation: conversationItem,
              });
            });
          }

          return Promise.resolve({
            hasNext: res?.__meta?.hasMore || false,
          });
        });
    },
    acquireConversation({ commit }, { tenantID, contactID }) {
      /**
       * Request conversation list batch from API
       */
      return Promise.resolve()
        .then(() => conversations.conversations(tenantID).one(contactID).get())
        .then((res) => {
          commit("assertConversations", {
            tenantID: tenantID,
          });
          return res;
        })
        .then((res) => {
          if (res.conversation) {
            commit("storeConversation", {
              tenantID: res.conversation.tenantID,
              contactID: res.conversation.contactID,
              conversation: res.conversation,
            });
          }

          return Promise.resolve();
        });
    },
  },
  mutations: {
    assertConversations(state, { tenantID }) {
      if ("object" !== typeof state.conversationsMap[tenantID]) {
        state.conversationsMap[tenantID] = {};
      }
      if ("object" !== typeof state.conversationsList[tenantID]) {
        state.conversationsList[tenantID] = [];
      }

      /**
       * Also assert sessions
       */
      this.commit("sessionsTalqui/assertTenantSessions", {
        tenantID: tenantID,
      });
    },
    assertConversation(state, { tenantID, contactID }) {
      if (
        "object" !== typeof state.conversationsMap[tenantID] ||
        "object" !== typeof state.conversationsList[tenantID]
      ) {
        this.commit("conversationsReinvent/assertConversations", {
          tenantID: tenantID,
        });
      }

      if ("object" !== typeof state.conversationsMap[tenantID][contactID]) {
        state.conversationsMap[tenantID][contactID] = {
          tenantID: tenantID,
          contactID: contactID,
          contactPhone: "",
          contactFirstname: `Visitante #${
            SESSION_PRONOUMS[
              parseInt(contactID.charAt(0), 16) % SESSION_PRONOUMS.length
            ]
          }`,
          contactLastname: "",
          createdAt: 0,
          updatedAt: 0,
          _$: {
            unreadCount: 0,
          },
        };
      }
    },
    storeConversation(state, { tenantID, contactID, conversation }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Asserting session to garantee sync between
       * both properties
       */
      this.commit("sessionsTalqui/storeSession", {
        tenantID: tenantID,
        sessionID: conversation.contactLastSessionID,
        session: {
          contactID: contactID,
          contact: conversation,
        },
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Merge new data from conversation to
       * the current conversation state
       */
      Object.assign(conversationState, {
        ...conversation,
      });

      if (conversationState.contactFirstname === "") {
        let pronoum =
          SESSION_PRONOUMS[
            parseInt(conversationState.contactID.charAt(0), 16) %
              SESSION_PRONOUMS.length
          ];
        conversationState.contactFirstname = `Visitante #${pronoum}`;
      }

      /**
       * Upserting last message to pool
       */
      conversationState._$ = conversationState._$ || {};
      conversationState._$.unreadCount = conversation.__unreadCounter;
      conversationState._$.lastMessage = Object.assign(
        {
          tenantID: tenantID,
          contactID: contactID,
          sessionID: conversationState.contactLastSessionID,
          messageDirection: "inbound",
          messageKey: "text",
          messageStatus: 3,
          messageValue: "",
          messageFingerprint: null,
          createdAt: -1,
        },
        conversation.__lastMessage,
      );

      this.commit("conversationsReinvent/postStoreConversation", {
        tenantID,
        contactID,
        conversation: conversationState,
      });
    },

    updateLastMessage(state, { tenantID, contactID, message }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Upserting last message date/time reference
       */
      conversationState.contactLastMessageAt = message.createdAt;
      conversationState.contactLastSessionID = message.sessionID;

      /**
       * Acessory object - Ephemeral data, does not are part
       * of database content
       */
      conversationState._$ = conversationState._$ || {};

      /**
       * Counter of unread messages
       */
      conversationState._$.unreadCount = conversationState._$.unreadCount || 0;

      /**
       * Last message adapter
       */
      conversationState._$.lastMessage = Object.assign(
        {
          tenantID: tenantID,
          contactID: contactID,
          sessionID: conversationState.contactLastSessionID,
          messageDirection: "inbound",
          messageKey: "text",
          messageStatus: 3,
          messageValue: "",
          messageFingerprint: null,
          createdAt: -1,
        },
        message,
      );

      this.commit("conversationsReinvent/postStoreConversation", {
        tenantID,
        contactID,
        conversation: conversationState,
      });
    },

    addUnreadCounter(state, { tenantID, contactID }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Add number to counter of unread messages
       */
      conversationState._$.unreadCount =
        (conversationState._$.unreadCount || 0) + 1;
    },

    removeUnreadCounter(state, { tenantID, contactID }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Remove all unread counters, set value to zero
       */
      conversationState._$.unreadCount = 0;
    },

    postStoreConversation(state, { tenantID, contactID, conversation }) {
      /**
       * Update conversation in conversations array
       */
      let conversationIndex = this.getters[
        "conversationsReinvent/getConversationIndex"
      ](tenantID, contactID);
      if (conversationIndex < 0) {
        state.conversationsList[tenantID].push(conversation);
      } else {
        state.conversationsList[tenantID][conversationIndex] = conversation;
      }

      /**
       * Request a new order to reinvent conversations
       */
      this.commit("conversationsReinvent/orderSessions", {
        tenantID,
      });
    },
    orderSessions(state, { tenantID }) {
      if (state.conversationsList[tenantID]) {
        /**
         * Ordering conversations based on conversation that receive message latest
         */
        state.conversationsList[tenantID].sort(
          thenby.firstBy("contactLastMessageAt", -1),
        );
      }
    },
  },
};
