const DEFAULT_AVAILABILITY = "away";

export default {
  computed: {
    operatorMe() {
      return this.$store.getters["operatorsTalqui/getMe"];
    },
    mineDesiredAvailability() {
      return this.$store.getters["operatorsTalqui/getDesiredAvailability"];
    },
  },
  methods: {
    operatorAvailability(operator) {
      if (operator.operatorID === this.operatorMe.operatorID) {
        return this.mineDesiredAvailability;
      }

      if (operator?.operatorStatus?.operatorStatus === "online") {
        const TIME_SINCE_HEARTBEAT =
          (new Date().getTime() -
            new Date(operator.operatorStatus.lastHeartbeatAt).getTime()) /
          1000;

        /**
         * Detecting if operator is away due inactivity
         * When lastHeartbeat has more than 6 minutes (360seconds)
         */
        if (TIME_SINCE_HEARTBEAT < 360) {
          return "online";
        }
      }

      return DEFAULT_AVAILABILITY;
    },
  },
};
