import conversations from "@/classes/reinvent/conversations";
import thenby from "thenby";

const SESSION_PRONOUMS = [
  "Entusiasta",
  "Explorador",
  "Aventureiro",
  "Viajante",
  "Descobridor",
  "Desbravador",
  "Curioso",
  "Pioneiro",
  "Desbravador",
  "Inovador",
  "Destemido",
  "Corajoso",
  "Admirador",
];

export default {
  namespaced: true,
  state: {
    conversationsMap: {},
    conversationsList: {},
    currentConversationID: null,
    cacheTimeout: {},
    conversationsUnreadCount: {},
  },
  getters: {
    getConversationReference: (state) => (tenantID, contactID) =>
      state.conversationsMap[tenantID][contactID],
    getConversationIndex: (state) => (tenantID, contactID) =>
      (state.conversationsList[tenantID] || []).findIndex(
        (conv) => conv.contactID === contactID,
      ),
    getConversations: (state) => (tenantID) =>
      state.conversationsList[tenantID] || [],
    getConversationsUnreadCount: (state) => (tenantID) =>
      state.conversationsUnreadCount?.[tenantID] || 0,
  },
  actions: {
    acquireConversations({ commit }, { tenantID, page, filter, perPage = 10 }) {
      /**
       * Prepare parameters for
       * execute request
       */
      let conversationsBatch = [];
      let REQUEST_PARAMETERS = {
        page: page,
        perPage: perPage,
        ...(filter && { filter: filter }),
      };

      /**
       * Request conversation list batch from API
       */
      return Promise.resolve()
        .then(() =>
          conversations.conversations(tenantID).get(REQUEST_PARAMETERS),
        )
        .then((res) => {
          conversationsBatch = res?.conversations || [];

          commit("assertConversations", { tenantID: tenantID });

          return res;
        })
        .then((res) => {
          if (conversationsBatch.length > 0) {
            conversationsBatch
              .filter((conversation) => conversation.contactLastSessionID)
              .forEach((conversationItem) => {
                commit("storeConversation", {
                  tenantID: conversationItem.tenantID,
                  contactID: conversationItem.contactID,
                  conversation: conversationItem,
                });
              });
          }

          const conversationsBatchFormatted = conversationsBatch.map(
            (conversationItem) => {
              const conversationItemCopy = JSON.parse(
                JSON.stringify(conversationItem),
              );

              if (conversationItemCopy.contactFirstname === "") {
                let pronoum =
                  SESSION_PRONOUMS[
                    parseInt(conversationItemCopy.contactID.charAt(0), 16) %
                      SESSION_PRONOUMS.length
                  ];
                conversationItemCopy.contactFirstname = `Visitante #${pronoum}`;
              }

              conversationItemCopy._$ = conversationItemCopy._$ || {};

              conversationItemCopy._$.unreadCount =
                conversationItemCopy.__unreadCounter;

              conversationItemCopy._$.lastMessage = Object.assign(
                {
                  tenantID: tenantID,
                  contactID: conversationItemCopy.contactID,
                  sessionID: conversationItemCopy.contactLastSessionID,
                  messageDirection: "inbound",
                  messageKey: "text",
                  messageStatus: 3,
                  messageValue: "",
                  messageFingerprint: null,
                  createdAt: -1,
                },
                conversationItemCopy.__lastMessage,
              );

              return conversationItemCopy;
            },
          );

          return Promise.resolve({
            data: conversationsBatchFormatted,
            hasNext: res?.__meta?.hasMore || false,
          });
        });
    },
    acquireConversation({ commit }, { tenantID, contactID }) {
      /**
       * Request conversation list batch from API
       */
      return Promise.resolve()
        .then(() => conversations.conversations(tenantID).one(contactID).get())
        .then((res) => {
          commit("assertConversations", {
            tenantID: tenantID,
          });
          return res;
        })
        .then((res) => {
          if (res.conversation) {
            commit("storeConversation", {
              tenantID: res.conversation.tenantID,
              contactID: res.conversation.contactID,
              conversation: res.conversation,
            });
          }

          return Promise.resolve();
        });
    },
    acquireConversationsUnreadCount({ commit }, { tenantID }) {
      return Promise.resolve()
        .then(() =>
          conversations.conversations(tenantID).one("count-unread").get(),
        )
        .then((res) => {
          commit("setConversationsUnreadCount", {
            tenantID: tenantID,
            count: res.conversationsUnreadCount,
          });
        });
    },
  },
  mutations: {
    assertConversations(state, { tenantID }) {
      if ("object" !== typeof state.conversationsMap[tenantID]) {
        state.conversationsMap[tenantID] = {};
      }
      if ("object" !== typeof state.conversationsList[tenantID]) {
        state.conversationsList[tenantID] = [];
      }

      /**
       * Also assert sessions
       */
      this.commit("sessionsTalqui/assertTenantSessions", {
        tenantID: tenantID,
      });
    },
    assertConversation(state, { tenantID, contactID }) {
      if (
        "object" !== typeof state.conversationsMap[tenantID] ||
        "object" !== typeof state.conversationsList[tenantID]
      ) {
        this.commit("conversationsReinvent/assertConversations", {
          tenantID: tenantID,
        });
      }

      if ("object" !== typeof state.conversationsMap[tenantID][contactID]) {
        state.conversationsMap[tenantID][contactID] = {
          tenantID: tenantID,
          contactID: contactID,
          contactPhone: "",
          contactFirstname: `Visitante #${
            SESSION_PRONOUMS[
              parseInt(contactID.charAt(0), 16) % SESSION_PRONOUMS.length
            ]
          }`,
          contactLastname: "",
          createdAt: 0,
          updatedAt: 0,
          _$: {
            unreadCount: 0,
          },
        };
      }
    },
    storeConversation(state, { tenantID, contactID, conversation }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Asserting session to garantee sync between
       * both properties
       */
      this.commit("sessionsTalqui/storeSession", {
        tenantID: tenantID,
        sessionID: conversation.contactLastSessionID,
        session: {
          contactID: contactID,
          contact: conversation,
        },
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Merge new data from conversation to
       * the current conversation state
       */
      Object.assign(conversationState, {
        ...conversation,
      });

      if (conversationState.contactFirstname === "") {
        let pronoum =
          SESSION_PRONOUMS[
            parseInt(conversationState.contactID.charAt(0), 16) %
              SESSION_PRONOUMS.length
          ];
        conversationState.contactFirstname = `Visitante #${pronoum}`;
      }

      /**
       * Upserting last message to pool
       */
      conversationState._$ = conversationState._$ || {};
      conversationState._$.unreadCount = conversation.__unreadCounter;
      conversationState._$.lastMessage = Object.assign(
        {
          tenantID: tenantID,
          contactID: contactID,
          sessionID: conversationState.contactLastSessionID,
          messageDirection: "inbound",
          messageKey: "text",
          messageStatus: 3,
          messageValue: "",
          messageFingerprint: null,
          createdAt: -1,
        },
        conversation?.__lastMessage || conversation?.lastMessage,
      );

      this.commit("conversationsReinvent/postStoreConversation", {
        tenantID,
        contactID,
        conversation: conversationState,
      });
    },
    updateLastMessage(state, { tenantID, contactID, message }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Upserting last message date/time reference
       */
      conversationState.contactLastMessageAt = message.createdAt;
      conversationState.contactLastSessionID = message.sessionID;

      /**
       * Acessory object - Ephemeral data, does not are part
       * of database content
       */
      conversationState._$ = conversationState._$ || {};

      /**
       * Counter of unread messages
       */
      conversationState._$.unreadCount = conversationState._$.unreadCount || 0;

      /**
       * Last message adapter
       */
      conversationState._$.lastMessage = Object.assign(
        {
          tenantID: tenantID,
          contactID: contactID,
          sessionID: conversationState.contactLastSessionID,
          messageDirection: "inbound",
          messageKey: "text",
          messageStatus: 3,
          messageValue: "",
          messageFingerprint: null,
          createdAt: -1,
        },
        message,
      );

      this.commit("conversationsReinvent/postStoreConversation", {
        tenantID,
        contactID,
        conversation: conversationState,
      });
    },
    addUnreadCounter(state, { tenantID, contactID }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Add number to counter of unread messages
       */
      conversationState._$.unreadCount =
        (conversationState._$.unreadCount || 0) + 1;
    },
    removeUnreadCounter(state, { tenantID, contactID }) {
      this.commit("conversationsReinvent/assertConversation", {
        tenantID,
        contactID,
      });

      /**
       * Acquire conversation state from current store
       */
      let conversationState = this.getters[
        "conversationsReinvent/getConversationReference"
      ](tenantID, contactID);

      /**
       * Remove all unread counters, set value to zero
       */
      conversationState._$.unreadCount = 0;
    },
    postStoreConversation(state, { tenantID, contactID, conversation }) {
      /**
       * Update conversation in conversations array
       */
      let conversationIndex = this.getters[
        "conversationsReinvent/getConversationIndex"
      ](tenantID, contactID);
      if (conversationIndex < 0) {
        state.conversationsList[tenantID].push(conversation);
      } else {
        state.conversationsList[tenantID][conversationIndex] = conversation;
      }

      /**
       * Request a new order to reinvent conversations
       */
      this.commit("conversationsReinvent/orderSessions", {
        tenantID,
      });
    },
    orderSessions(state, { tenantID }) {
      if (state.conversationsList[tenantID]) {
        /**
         * Ordering conversations based on conversation that receive message latest
         */
        state.conversationsList[tenantID].sort(
          thenby.firstBy("contactLastMessageAt", -1),
        );
      }
    },
    clearConversations(state, { tenantID }) {
      state.conversationsList[tenantID] = [];
      state.conversationsMap[tenantID] = {};
    },
    incrementConversationsUnreadCount(state, { tenantID, contactID }) {
      const currentSession =
        this.getters["sessionsTalqui/getCurrentSession"](tenantID);

      const persistedCount = state.conversationsUnreadCount?.[tenantID] || {
        count: 0,
        contacts: [],
      };

      /**
       * if is the current contact open or
       * contact already has a unread message
       */
      if (
        currentSession?.contactID === contactID ||
        persistedCount.contacts.includes(contactID)
      )
        return;

      state.conversationsUnreadCount[tenantID] = {
        count: persistedCount.count + 1,
        contacts: [...persistedCount.contacts, contactID],
      };
    },
    decrementConversationsUnreadCount(state, { tenantID, contactID }) {
      const persistedCount = state.conversationsUnreadCount?.[tenantID] || {
        count: 0,
        contacts: [],
      };

      if (persistedCount.count <= 0) return;

      state.conversationsUnreadCount[tenantID] = {
        count: persistedCount.count - 1,
        contacts: !persistedCount.contacts.includes(contactID)
          ? persistedCount.contacts
          : persistedCount.contacts?.filter((item) => item !== contactID),
      };
    },
    setConversationsUnreadCount(state, { tenantID, count }) {
      state.conversationsUnreadCount[tenantID] = { count, contacts: [] };
    },
  },
};
