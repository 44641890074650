import http from "./http";
import tenant from "./tenant";
class Plugins {
  pluginList() {
    return http.one("plugins/");
  }

  pluginRead({ pluginID }) {
    return http.one("plugins").one(pluginID);
  }

  pluginConnectionList({ tenantID }) {
    return tenant.tenantOne(tenantID).one("plugins").one("connections");
  }

  pluginConnectionUpsert({ tenantID, pluginID }) {
    return tenant
      .tenantOne(tenantID)
      .one("plugins")
      .one(pluginID)
      .one("connection");
  }

  pluginPreInstall({ tenantID, pluginID }) {
    return tenant
      .tenantOne(tenantID)
      .one("plugins")
      .one(pluginID)
      .one("preinstall");
  }

  pluginConnectionStatus({ tenantID, pluginConnectionID }) {
    return tenant
      .tenantOne(tenantID)
      .one("plugins")
      .one("connections")
      .one(pluginConnectionID)
      .one("urn")
      .one("whatsapp-secondary")
      .one("status");
  }
}
export default new Plugins();
