import store from "@/store";
import routerResolvers from "@/classes/talqui/_routerResolver";
import socket from "@/classes/talqui/socket";
import router from "@/router/index.js";
import auth from "@/classes/talqui/auth";
import tenant from "@/classes/talqui/tenant";
import operation from "@/classes/talqui/operation";
const WHATSAPP_PLUGIN_URN = "urn:talqui:whatsapp-secondary:0";
import eventStorm from "@/classes/reinvent/eventStorm";

export default {
  tenantID: [
    () => {
      const $route = routerResolvers.route;
      return store.commit("tenantsTalqui/setTenantID", $route.params.tenantID);
    },
  ],
  defaultWebsiteRedirect: [
    () => {
      return tenant
        .tenantList()
        .get()
        .then((res) => {
          if (res?.tenants?.length > 0) {
            store.commit("tenantsTalqui/setTenantID", res.tenants[0].tenantID);
            router
              .push({
                name: "app.website.plugins.settings",
                params: {
                  tenantID: res.tenants[0].tenantID,
                  pluginID: process.env.VUE_APP_REINVENT_PLUGIN,
                },
              })
              .catch(() => {});
            return Promise.resolve();
          }
        });
    },
  ],
  me: [
    "me",
    () => {
      // console.log("@resolver:me");
      /**
       * Notificating operator-availability before anything
       */
      auth.doHeartbeat(true);
      return store.dispatch("operatorsTalqui/acquireMe");
    },
  ],
  logout: [
    () => {
      store.dispatch("operatorsTalqui/logout");
      store.dispatch("tenantsTalqui/logout");
      auth.stopAvailabilityHeartbeat();
      return true;
    },
  ],
  tenants: [
    () => {
      // console.log("@resolver:tenants");
      return store.dispatch("tenantsTalqui/acquireTenants");
    },
  ],
  operators: [
    () => {
      // console.log("@resolver:operators");
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      store.dispatch("operatorsTalqui/acquireOperators", {
        tenantID: $tenantID,
      });
      return true;
    },
  ],
  pluginsConnections: [
    async () => {
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      const pluginsConnections = await store.dispatch(
        "pluginsTalqui/acquirePluginConnections",
        {
          tenantID: $tenantID,
        },
      );

      const whatsAppPlugin = pluginsConnections?.find(
        (plugin) => plugin?.pluginURN === WHATSAPP_PLUGIN_URN,
      );

      const response = await store.dispatch(
        "pluginsTalqui/pluginConnectionStatus",
        {
          tenantID: $tenantID,
          pluginConnectionID: whatsAppPlugin.pluginConnectionID,
        },
      );

      eventStorm.eventsInbound["plugin:whatsappConnection"](
        response?.connected,
      );

      return true;
    },
  ],

  conversations: [
    async () => {
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];

      store.dispatch("conversationsReinvent/acquireConversationsUnreadCount", {
        tenantID: $tenantID,
      });
    },
  ],

  socket: [
    () => {
      // console.log("@resolver:socket");
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      let __token = store.getters["operatorsTalqui/getToken"];
      socket.__connect({ tenantID: $tenantID, token: __token }).then(() => {
        // necessary to bind socket events
        operation.bindSocket("ping:alive", () => {});
      });
      return Promise.resolve();
    },
  ],

  notification: [
    () => {
      // console.log("@resolver:notification");
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      store.dispatch("notificationsTalqui/acquireNotifications", {
        tenantID: $tenantID,
      });
      return true;
    },
  ],

  subscription: [
    () => {
      // console.log("@resolver:subscription");
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      store.dispatch("subscriptionsTalqui/acquireSubscription", {
        tenantID: $tenantID,
      });
      return true;
    },
  ],

  tenantSessionMotives: [
    () => {
      // console.log("@resolver:tenantSessionMotives");
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      store.dispatch("tenantsTalqui/acquireSettingsSessionMotives", {
        tenantID: $tenantID,
      });
      return true;
    },
  ],

  campaign: [
    () => {
      const $route = routerResolvers.route;
      let $tenantID =
        $route.params.tenantID || store.getters["tenantsTalqui/getTenantID"];
      store.commit("campaignsTalqui/assertCampaignTenant", {
        tenantID: $tenantID,
      });
    },
  ],

  // currentSession: [
  //   () => {
  //     console.log("@resolver:currentSession");
  //     const $route = routerResolvers.route;
  //     const $sessionID = $route.params.sessionId;
  //     // console.log($tenantID, $sessionID);
  //     return store.dispatch("sessionsTalqui/setCurrentSessionID", {
  //       value: $sessionID,
  //     });
  //   },
  // ],
};
