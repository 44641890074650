// var a = o(i("c832"));
// const n = ["updated_at", "created_at"];

class _queryBuilder {
  filterConversationItem(
    session,
    activeFilter,
    activeSessionKind = "both",
    operatorID,
  ) {
    if (session.lastMessage) {
      const filterKind =
        activeSessionKind === "both" ||
        session.sessionKind === activeSessionKind;

      /**
       * Switch session filter
       */
      switch (activeFilter) {
        case "auto":
          return (
            session.sessionActive === true &&
            session.sessionType === "auto" &&
            filterKind
          );
        case "queued":
          return (
            session.sessionActive === true &&
            session.sessionType === "queued" &&
            filterKind
          );
        case "manual":
          return (
            session.sessionActive === true &&
            session.sessionType === "manual" &&
            filterKind
          );
        case "inactive":
          return session.sessionActive === false && filterKind;
        case "mine":
          return (
            session.sessionActive === true &&
            session.sessionType === "manual" &&
            session.operatorID === operatorID &&
            filterKind
          );
      }
    }

    return !1;
  }
  filterGenericItem(e, t) {
    let i = t.filter,
      a = !0;
    for (let o = 0; o < i.length; o++) {
      let n = i[o],
        r = this.__applyFilter(e, n);
      if ("and" === t.operator && ((a = a && r), !1 === a)) break;
      "or" === t.operator && (a = a || r);
    }
    return a;
  }
  __applyFilter(e, t) {
    console.error("TALQUI-STOP: 0001");
    console.log(e, t);
    // let i, o, r;
    // return (
    //   (i = this["__itemQuery_" + t.operator].bind(this)),
    //   (o = (0, a.default)(e, t.criterion)),
    //   (r = this.__transformValue(t.query)),
    //   (o =
    //     -1 !== n.indexOf(t.criterion)
    //       ? this.__transformDate(o, t.criterion)
    //       : this.__transformValue(o, t.criterion)),
    //   !!i && i(o, r)
    // );
  }
  __transformValue(e) {
    return "string" === typeof e
      ? e.toLowerCase()
      : "number" === typeof element
      ? e.toString()
      : Array.isArray(e)
      ? e.map((e) => this.__transformValue(e))
      : e;
  }
  __transformDate(e) {
    let t = Math.max(0, isNaN(e) ? 0 : parseInt(e, 10));
    return (Date.now() - t).toString();
  }
  __itemQuery_eq(e, t) {
    let i = !1;
    return Array.isArray(e)
      ? ((i = t.some((t) => e.includes(t))), i)
      : ((i = t.some((t) => e == t)), i);
  }
  __itemQuery_ex(e) {
    return "undefined" !== typeof e;
  }
  __itemQuery_has(e, t) {
    let i = !1;
    return Array.isArray(e)
      ? ((i = t.some((t) => e.includes(t))), i)
      : ((i = t.some((t) => -1 !== e.indexOf(t))), i);
  }
  __itemQuery_sw(e, t) {
    let i = !1;
    return (
      !e ||
      0 === t.length ||
      ((i = t.some((t) => e.toString().startsWith(t))), i)
    );
  }
  __itemQuery_ew(e, t) {
    let i = !1;
    return (
      !e || 0 === t.length || ((i = t.some((t) => e.toString().endsWith(t))), i)
    );
  }
  __itemQuery_gte(e, t) {
    let i = !1;
    return 0 === t.length || ((i = t.some((t) => e >= t)), i);
  }
  __itemQuery_lte(e, t) {
    let i = !1;
    return 0 === t.length || ((i = t.some((t) => e <= t)), i);
  }
  __itemQuery_gt(e, t) {
    let i = !1;
    return 0 === t.length || ((i = t.some((t) => e > t)), i);
  }
  __itemQuery_lt(e, t) {
    let i = !1;
    return 0 === t.length || ((i = t.some((t) => e < t)), i);
  }
  __itemQuery_neq(e, t) {
    return !1 === this.__itemQuery_eq(e, t);
  }
  __itemQuery_nex(e) {
    return !1 === this.__itemQuery_ex(e);
  }
  __itemQuery_nhas(e, t) {
    return !1 === this.__itemQuery_has(e, t);
  }
}

export default new _queryBuilder();
