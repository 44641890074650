import SmoothScroll from "smooth-scroll";
// import o from "./auth.js";

const SPEED_SMOOTH_SCROLL = 500;
const DEFAUT_SCROLL_ANIMATION_TIME = 1e3;

class Focus {
  constructor() {
    this.pendingFocusActions = {};
    this.inFocus = document.hasFocus() && true;

    this.smooth_scroll = new SmoothScroll("a[href*='#']", {
      speed: SPEED_SMOOTH_SCROLL,
      updateURL: false,
      popstate: false,
      speedAsDuration: true,
    });

    window.addEventListener(
      "focus",
      () => {
        if (true !== this.inFocus) {
          this.inFocus = true;
          // o.default.onViewFocus();
          this.unstackFocusActions();
        }
      },
      true,
    );

    window.addEventListener(
      "blur",
      () => {
        if (true === this.inFocus) {
          this.inFocus = false;
          // o.default.onViewBlur();
        }
      },
      true,
    );
  }
  purgeFocusActions(actionName) {
    this.pendingFocusActions[actionName] &&
      delete this.pendingFocusActions[actionName];
  }
  runInFocus(actionName, actionFunction, i = false) {
    if (true === this.inFocus || true === i) {
      actionFunction();
    } else {
      this.pendingFocusActions[actionName] ||
        (this.pendingFocusActions[actionName] = []),
        this.pendingFocusActions[actionName].push(actionFunction);
    }
  }
  unstackFocusActions() {
    for (let actionName in this.pendingFocusActions) {
      while ((this.pendingFocusActions[actionName] || []).length > 0) {
        let _actionFunction = this.pendingFocusActions[actionName][0];
        if ("function" === typeof _actionFunction) {
          _actionFunction();
          this.pendingFocusActions[actionName].shift();
        }
      }
    }
    this.pendingFocusActions = {};
  }
  scrollTo(e, customScrollTime = null) {
    e &&
      setTimeout(
        () => {
          let t = e();
          t && this.smooth_scroll.animateScroll(t);
        },
        null !== customScrollTime
          ? customScrollTime
          : DEFAUT_SCROLL_ANIMATION_TIME,
      );
  }
}

export default new Focus();
