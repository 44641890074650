<template>
  <div v-show="loading">
    <div :class="['lds-spinner', size]">
      <div
        v-for="(item, index) in new Array(spinnerBulletsNumber)"
        :key="index"
        :style="[
          `transform: rotate(${index * spinnerRotationDeg}deg)`,
          `animation-delay: ${index / spinnerAnimationDelay}s;`,
        ]"
      ></div>
    </div>
  </div>
</template>

<script setup>
const spinnerBulletsNumber = 8;
const spinnerRotationDeg = 45;
const spinnerAnimationDelay = 10 - 1.1;

defineProps({
  loading: { type: Boolean, default: true },
  size: { type: String, default: "normal" },
});
</script>

<style lang="scss" scoped>
.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 60px;
}
.lds-spinner div {
  transform-origin: 40px 30px;
  color: rgba(255, 255, 255, 0.8);
  animation: lds-spinner 1s linear infinite;
}

.lds-spinner.small div:after {
  content: "";
  display: block;
  position: absolute;
  top: 18.2px;
  left: 38px;
  width: 2.87px;
  height: 5.67px;
  border-radius: 4px;
  background: currentColor;
}

.lds-spinner.normal div:after {
  content: "";
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 5.87px;
  height: 14.67px;
  // top: 18.2px;
  // left: 38px;
  // width: 2.87px;
  // height: 5.67px;
  border-radius: 4px;
  background: currentColor;
}

@keyframes lds-spinner {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
</style>
