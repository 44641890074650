<template>
  <validation-observer as="" v-slot="{ meta }">
    <base-popup
      v-on:close="(t) => this.$emit('close')"
      v-on:enter="(e) => onCreateConversation(!meta.valid)"
      parent="inbox"
      size="medium"
    >
      <template #actions>
        <BaseButton
          v-on:click="(t) => this.$emit('close')"
          color="black"
          reverse=""
        >
          {{ this.$t("BUTTONS.CANCEL") }}
        </BaseButton>
        <BaseButton
          v-on:click="(e) => onCreateConversation(!meta.valid)"
          :disabled="!meta.valid"
          :loading="loading"
          left-icon="add_circle"
        >
          {{ this.$t("INBOX.LIST.POPUPS_NEW_CONVERSATION_PROCEED") }}
        </BaseButton>
      </template>

      <FieldInput
        class="u-mb20"
        v-model="nickname"
        :disabled="loading"
        :label="this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_NAME_LABEL')"
        :placeholder="
          this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_NAME_PLACEHOLDER')
        "
        name="nickname"
        rules="required"
        ref="nicknameInput"
        autofocus=""
      ></FieldInput>

      <FieldSelect
        class="u-mb20"
        v-model="type"
        :disabled="loading"
        :options="createOptions"
        name="type"
      ></FieldSelect>

      <template v-if="type === 'email'">
        <FieldInput
          class="u-mb20"
          v-model="email"
          :disabled="loading"
          :label="this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_EMAIL_LABEL')"
          :placeholder="
            this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_EMAIL_PLACEHOLDER')
          "
          name="email"
          rules="required|email|max:100"
        ></FieldInput>
        <FieldInput
          class="u-mb20"
          v-model="subject"
          :disabled="loading"
          :label="this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_SUBJECT_LABEL')"
          :placeholder="
            this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_SUBJECT_PLACEHOLDER')
          "
          name="subject"
          rules="max:80"
        ></FieldInput>
      </template>

      <FieldInput
        v-else-if="type === 'phone'"
        class="u-mb20"
        v-model="phone"
        :disabled="loading"
        :label="this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_PHONE_LABEL')"
        :placeholder="
          this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_PHONE_PLACEHOLDER')
        "
        name="phone"
        rules="required"
      ></FieldInput>

      <template v-else-if="type === 'whatsapp-secondary'">
        <FieldInput
          class="u-mb20"
          v-model="whatsapp"
          :disabled="loading"
          :label="this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_WHATSAPP_LABEL')"
          :placeholder="
            this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_WHATSAPP_PLACEHOLDER')
          "
          name="whatsapp"
          rules="required"
          mask="['+55 ## #####-####', '+55 ## ####-####']"
        ></FieldInput>

        <FieldInput
          class="u-mb20"
          v-model="messageValue"
          :disabled="loading"
          :label="
            this.$t('INBOX.LIST.POPUPS_NEW_CONVERSATION_WHATSAPP_START_LABEL')
          "
          :placeholder="
            this.$t(
              'INBOX.LIST.POPUPS_NEW_CONVERSATION_WHATSAPP_START_PLACEHOLDER',
            )
          "
          name="messageValue"
          rules="required|max:255"
        ></FieldInput>
      </template>
    </base-popup>
  </validation-observer>
</template>
<script>
import BaseToast from "@/components/Base/BaseToast.vue";

export default {
  emits: ["close"],
  data() {
    return {
      tenantID: this.$route.params.tenantID,
      loading: false,
      email: "",
      phone: "",
      whatsapp: "",
      nickname: "",
      subject: "",
      type: "whatsapp-secondary",
      messageValue: "Olá, está podendo falar?",
    };
  },
  computed: {
    createOptions() {
      return [
        // {
        //   value: "email",
        //   label: this.$t("INBOX.LIST.POPUPS_NEW_CONVERSATION_KEYS_EMAIL"),
        // },
        {
          value: "whatsapp-secondary",
          label: this.$t(
            "INBOX.LIST.POPUPS_NEW_CONVERSATION_KEYS_WHATSAPPSECONDARY",
          ),
        },
      ];
    },
  },
  methods: {
    onCreateConversation(isInvalid = false) {
      if (true !== this.loading && true !== isInvalid) {
        this.loading = true;

        var contactID = this.whatsapp;
        if (this.type === "whatsapp-secondary") {
          contactID = contactID.replace(/\D/giu, "");
        }

        this.$store
          .dispatch("sessionsTalqui/sessionStart", {
            tenantID: this.tenantID,
            channel: this.type,
            contactExternalID: contactID,
            contactFullName: this.nickname,
            messageKey: "text",
            messageValue: this.messageValue,
          })
          .then((res) => {
            BaseToast.success("inbox_conversation_created");
            this.$emit("close");
            this.$router
              .push({
                name: "app.website.inbox.index.reinvent",
                params: {
                  tenantID: this.tenantID,
                  sessionId: res.session.sessionID,
                },
              })
              .catch(() => {});
            Promise.resolve();
          })
          .catch(() => Promise.resolve())
          .then(() => {
            this.loading = false;
          });
      }
    },
    onFieldKeydown(e, t) {
      if ("Tab" === t.code) {
        t.preventDefault();
        t.stopPropagation();
        this.$refs.nicknameInput.focusField();
      }
    },
  },
};
</script>
