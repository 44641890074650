<template>
  <a
    v-crispRipple
    :class="[
      'c-conversation-menu-item',
      'c-conversation-menu-item--' + sessionState,
      {
        'c-conversation-menu-item--active': active,
        'c-conversation-menu-item--new': conversationIsNew,
        'c-conversation-menu-item--has-unread': conversationUnreadOperator > 0,
      },
    ]"
    v-on:click="(...e) => onClick(...e)"
    draggable="false"
  >
    <div>
      <BaseAvatar
        class="c-conversation-menu-item__avatar"
        :availability="conversationIsBlocked ? null : conversationAvailability"
        :avatar-id="conversation.contactID"
        :avatar-url="conversationAvatarUrl"
        :nickname="conversationNickname"
        size="medium"
        :channel="conversationChannel"
      />

      <div class="c-conversation-menu-item__container">
        <h3
          v-if="conversation.contactFirstname"
          class="c-conversation-menu-item__headlines"
        >
          <span
            v-if="assigned && !conversationIsBlocked"
            class="c-conversation-menu-item__nickname u-bold"
          >
            {{ this.$filters.format.parseFirstName(conversationNickname) }}
          </span>
          <span v-else class="c-conversation-menu-item__nickname u-bold">
            {{ conversationNickname }}
          </span>
          <BaseIcon
            v-if="conversationIsBlocked"
            class="c-conversation-menu-item__block"
            name="block"
            size="12px"
          />
          <span
            v-if="assigned && !conversationIsBlocked"
            class="c-conversation-menu-item__assignment"
          >
            <BaseIcon
              class="c-conversation-menu-item__chevron"
              name="chevron_right"
              size="15px"
            />
            <BaseTag
              size="mini"
              palette="segment"
              :hasRemove="false"
              :filled="true"
              :rounded="true"
              :uppercase="false"
              :label="assigned"
            />
          </span>
        </h3>

        <p
          :class="[
            'c-conversation-menu-item__last-message',
            {
              'u-bold':
                'pending' === sessionState || conversationUnreadOperator > 0,
            },
          ]"
          :innerHTML="this.$filters.format.parseText(conversationLastMessage)"
        ></p>
      </div>

      <div class="c-conversation-menu-item__status">
        <div class="c-conversation-menu-item__info">
          <span
            v-if="conversationIsMentioned"
            class="c-conversation-menu-item__mention u-has-tooltip"
          >
            <BaseTooltip direction="bottom" align="right">
              {{ this.$t("TOOLTIPS.INBOX_CONVERSATION_MENTIONED") }}
            </BaseTooltip>
          </span>

          <span
            v-crispDate="{
              date: new Date(
                conversation?.contactLastMessageAt || -1,
              ).getTime(),
              maintain: true,
            }"
            class="c-conversation-menu-item__updated-at"
          >
          </span>
        </div>

        <div
          v-if="sessionState !== 'resolved'"
          class="c-conversation-menu-item__wrapper"
        >
          <BaseNotification
            v-if="conversationUnreadOperator > 0"
            class="c-conversation-menu-item__state"
            color="red"
            size="none"
            slot-class="c-conversation-menu-item__state-slot"
          >
            <span class="u-bold">
              {{
                conversationUnreadOperator > 9
                  ? "9+"
                  : conversationUnreadOperator
              }}
            </span>
          </BaseNotification>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import mixinsSession from "@/mixins/session";
import { IdState } from "vue-virtual-scroller";
import BaseNotification from "@/components/Reinvent/Base/BaseNotification";
import ConversationBoxContentMessageBubble from "@/components/Reinvent/Conversation/ConversationBoxContentMessageBubble.vue";

export default {
  components: {
    BaseNotification,
    ConversationBoxContentMessageBubble,
  },
  mixins: [
    IdState({
      idProp: (e) => e.conversation.contactID,
    }),
    mixinsSession,
  ],
  props: {
    tenantID: {
      type: String,
      required: true,
    },
    sessionID: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    conversation: {
      type: Object,
      required: true,
    },
    conversationChannel: {
      type: String,
      required: true,
    },
    conversationAvailability: {
      type: String,
      required: true,
    },
    conversationAssignedUser: {
      type: String,
      default: "",
    },
    conversationIsBlocked: {
      type: Boolean,
      default: false,
    },
    conversationIsMentioned: {
      type: Boolean,
      default: false,
    },
    conversationLastMessage: {
      type: String,
      required: true,
    },
    conversationNickname: {
      type: String,
      required: true,
    },
    conversationIsNew: {
      type: Boolean,
      default: false,
    },
    conversationAvatarUrl: {
      type: String,
      default: "",
    },
    conversationUnreadOperator: {
      type: Number,
      default: 0,
    },
    conversationOperators: {
      type: Object,
      default: null,
    },
    conversationComposeOperator: {
      type: Object,
      default: () => ({}),
    },
    conversationComposeVisitor: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["click"],
  idState() {
    return {
      operators: [],
    };
  },
  computed: {
    sessionState() {
      return "inbound";
    },
    displayedConversationOperators() {
      return Object.values(this.conversationOperators || {}).filter(
        (e) => !this.operatorTyping || this.operatorTyping === e.user_id,
      );
    },
    country() {
      if (
        this.conversation.meta &&
        this.conversation.meta.device &&
        this.conversation.meta.device.geolocation &&
        this.conversation.meta.device.geolocation.country
      )
        return this.conversation.meta.device.geolocation.country;
    },
    notificationColor() {
      return "state-" + this.sessionState;
    },
    isVisitorTyping() {
      // return (
      //   !this.conversationIsBlocked &&
      //   ((this.conversationComposeVisitor[this.conversation.session_id] || {})
      //     .ongoing ||
      //     false)
      // );
      return false;
    },
    operatorTyping() {
      if (!this.conversationIsBlocked) {
        const e = this.conversationComposeOperator;
        for (let t in e)
          if (e[t].ongoing && t !== this.me.user_id) return e[t].user.user_id;
      }
    },
    composeContent: {
      get() {
        return this.conversationComposeVisitor[this.conversation.sessionID]
          .excerpt;
      },
    },
    assigned: {
      get() {
        return this.conversationAssignedUser;
      },
    },
    me() {
      return this.$store.getters["user/getMe"];
    },
  },
  mounted() {
    this.$store.commit("operatorsTalqui/assertOperators", {
      tenantID: this.tenantID,
    });
  },
  methods: {
    onClick(e) {
      this.$emit("click", (this.conversation || {}).contactLastSessionID);
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.c-conversation-menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px;
  height: 89px;
  cursor: pointer;
  > div {
    border-bottom: 1px solid rgba(94, 94, 94, 0.2);
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50px 1fr 10px;
    gap: 10px;
    align-items: center;
  }
  .c-conversation-menu-item__container {
    .c-conversation-menu-item__headlines {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 8px;
      color: #fff;
      font-size: 15.5px;
      height: 23px;
      .c-conversation-menu-item__assignee {
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .c-conversation-menu-item__nickname {
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .c-conversation-menu-item__assignment {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #75869c;
        letter-spacing: -0.3px;
        font-weight: 600;
        font-size: 14.5px;
        overflow: hidden;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        .c-conversation-menu-item__chevron {
          margin-left: 1px;
          fill: #75869c;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
        }
      }
      .c-conversation-menu-item__block {
        margin-left: 6px;
        margin-top: -1px;
        fill: #e0102b;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
      }
    }
    .c-conversation-menu-item__last-message {
      pointer-events: none;
      overflow: hidden;
      margin-bottom: 0;
      max-height: 36px;
      color: #fff;
      -webkit-hyphens: auto;
      hyphens: auto;
      font-size: 14px;
      line-height: 18px;
      .o-markdown.o-markdown-code-block {
        padding: 0;
      }
      .o-markdown.o-markdown-code-inline {
        padding: 0;
      }
      .o-markdown.o-markdown-image {
        line-height: 0;
        .o-markdown-video-preview {
          line-height: 0;
        }
      }
      .o-markdown.o-markdown-video {
        line-height: 0;
        .o-markdown-video-preview {
          line-height: 0;
        }
      }
      .o-markdown.o-markdown-blockquote {
        margin-top: -1px;
      }
    }
    .c-conversation-menu-item__compose {
      line-height: 0;
    }
  }
  .c-conversation-menu-item__status {
    position: absolute;
    right: 15px;
    .c-conversation-menu-item__info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 8px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      .c-conversation-menu-item__mention {
        display: block;
        margin-top: 4px;
        margin-right: 4px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #0093ee;
        .c-base-tooltip[data-align="right"] {
          margin-right: -8px;
          &:after {
            margin-right: 8px;
          }
        }
      }
      .c-conversation-menu-item__updated-at {
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
      }
    }
    .c-conversation-menu-item__wrapper {
      position: relative;
      float: right;
      .c-conversation-menu-item__state {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 20px;
        height: 20px;
        font-size: 13px;
        line-height: 0;
        .c-conversation-menu-item__state-slot {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
      .c-conversation-menu-item__operators {
        position: absolute;
        top: 11px;
        right: 19px;
        width: 60px;
        height: 22px;
        .c-conversation-menu-item__operator {
          display: block;
          float: right;
          margin-left: -4px;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
          -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          display: none;
          img {
            border: 2px solid #fff;
          }
          &:last-of-type {
            margin-right: 0;
          }
          &:first-child {
            display: initial;
          }
          &:nth-child(2) {
            display: initial;
          }
          &:nth-child(3) {
            display: initial;
          }
          .c-base-tooltip[data-align="right"] {
            margin-right: -4px;
            &:after {
              margin-right: 8px;
            }
          }
        }
        .c-conversation-menu-item__operator--composing {
          .c-base-tooltip[data-align="right"] {
            margin-right: 0;
          }
        }
      }
      .c-conversation-menu-item__operators--compose {
        margin-right: -4px;
      }
    }
  }
  &:hover {
    &:not(.c-conversation-menu-item--active) {
      background-color: rgba(0, 0, 0, 0.1);
      .c-base-avatar__availability {
        border-color: rgba(0, 0, 0, 0.1) !important;
      }
      .c-base-flag__inner {
        border-color: rgba(0, 0, 0, 0.1) !important;
      }
      .c-conversation-menu-item__operators--normal {
        img {
          border-color: rgba(0, 0, 0, 0.1) !important;
        }
      }
    }
  }
}
.c-conversation-menu-item--active {
  background-color: rgba(0, 0, 0, 0.1) !important;
  .c-base-avatar__availability {
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
  .c-base-flag__inner {
    border-color: rgba(0, 0, 0, 0.1) !important;
  }
  .c-conversation-menu-item__operators--normal {
    img {
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}
.c-conversation-menu-item--new {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
